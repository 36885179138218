<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <b-row>
          <search @refreshTable=refetchData @exportData=exportData />
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                :to="{ name: 'apps-financepayment-edit'}"
              >
                <span class="text-nowrap">添加</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(fPaymentType)="data">
           {{ getCodeLabel("f_payment_type",data.item.f_payment_type)}}
        </template>

        <template #cell(fPaymentNo)="data">
           {{ data.item.f_payment_no}}
        </template>

        <template #cell(itemOrderNo)="data">
           {{ data.item.item_order_no}}
        </template>

        <template #cell(oaWorkflowNo)="data">
           {{ data.item.oa_workflow_no}}
        </template>

        <template #cell(businessDate)="data">
           {{ toDate(data.item.business_date)}}
        </template>

        <template #cell(businessUnitName)="data">
           {{ data.item.business_unit_name}}
        </template>

        <template #cell(paidAmount)="data">
           {{ data.item.paid_amount}}
        </template>

        <template #cell(payeeName)="data">
           {{ data.item.payee_name}}
        </template>

        <template #cell(paymentOrgName)="data">
           {{ data.item.payment_org_name}}
        </template>
        <template #cell(status)="{value}">
          <b-badge
              pill
              :variant="`light-${getCodeColor('f_payment_status',value)}`"
          >
            {{ getCodeLabel('f_payment_status', value) }}
          </b-badge>
        </template>

        <template #cell(remark)="data">
          <div :id="`remark-row-${data.item.f_payment_id}`">
            {{ data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
          </div>
          <b-tooltip
              :target="`remark-row-${data.item.f_payment_id}`"
              placement="top"
              v-if="data.value"
          >
            {{ data.value }}
          </b-tooltip>
        </template>

        <template #cell(attachments)="data">
          <div class="attachment-wrapper files">
            <b-dropdown
                variant="link"
                no-caret
                v-if="data.item.attachments"
            >
              <template #button-content>
                <feather-icon
                    icon="LinkIcon"
                    size="16"
                    class="align-middle text-body"
                />
              </template>
              <b-dropdown-item v-for="(attachment, index) in data.item.attachments.split('|')" :key="index" :href="attachment"
                               target="_blank">
                <b-img
                    :src="attachment"
                    alt="image"
                />
                <span>附件{{ index+1 }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-financepayment-view', query: { id: data.item.f_payment_id },params:{readonly:true} }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">浏览</span>
            </b-dropdown-item>

            <div v-if="data.item.status === '1'">
              <b-dropdown-item :to="{ name: 'apps-financepayment-edit', query: { id: data.item.f_payment_id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">编辑</span>
              </b-dropdown-item>
              <b-dropdown-item @click="approve(data.item.f_payment_id,2)" >
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">提交审核</span>
              </b-dropdown-item>
            </div>

            <div v-if="auth && data.item.status === '2'">
              <b-dropdown-item @click="approve(data.item.f_payment_id,3)">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">通过</span>
              </b-dropdown-item>

              <b-dropdown-item @click="approve(data.item.f_payment_id,1)">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">驳回</span>
              </b-dropdown-item>
            </div>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, reactive, toRefs, onMounted } from '@vue/composition-api'
import {  getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import financepaymentUseList from './financepaymentUseList'
import financepaymentStore from './financepaymentStore'
import Search from './search'
import { toDate } from '@core/utils/dayjs/utils'
import { useToast } from 'vue-toastification/composition'
import { getUserData } from '@/auth/utils'
import axios from "@/libs/axios";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";

export default {
  components: {
    Search,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AttachmentUpload,
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('financepayment')) store.registerModule('financepayment', financepaymentStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('financepayment')) store.unregisterModule('financepayment')
    })

    const state = reactive({
      auth:false
    })

    onMounted(()=>{
      const { role_id } = getUserData()
      //财务主管和超管
      if (role_id === 14 || role_id === 12){
        state.auth = true
      }
    })
    const exportData = function (){
      const condition = store.getters['financepayment/getCondition']
      const params = {
        order_by: this.orderBy,
        order_desc: this.isSortDirDesc === true ? 'desc':'',
      }
      Object.assign(params,condition)
      axios({
            url: '/api/exportexcelfile/exportFinancePayment',
            method:'get',
            params: params,
            responseType: 'blob',
          }
      ).then(res => {
        let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "付款单-"+new Date().getTime();
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download',filename+'.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }
    const methods = {
      approve(id, status) {
        store.dispatch('financepayment/approve', {
          id: id,
          status: status
        })
            .then(res => {
              if (res.data.code === 0) {
                if (status === 1) {
                  toast.success('驳回成功')
                } else if (status === 3) {
                  toast.success('审批成功')
                }
                refetchData()
              } else {
                toast.error(res.data.data)
              }
            })
      },
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

    } = financepaymentUseList()

    return {
      ...toRefs(state),
      ...methods,

      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      exportData,

      getCodeOptions,
      getCode,
      toDate,
      getCodeLabel,
      getCodeColor,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
