import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getCodeLabel } from '@core/utils/filter'
import { toDate } from '@core/utils/dayjs/utils'

export default function financepaymentUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)


  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },
    { key: 'fPaymentType', label: '单据类型', sortable: true },
    { key: 'fPaymentNo', label: '付款单编号', sortable: true },
    { key: 'itemOrderNo', label: '订单编号', sortable: true },
    { key: 'oaWorkflowNo', label: 'OA流程编号', sortable: true },
    { key: 'businessDate', label: '业务日期', sortable: true },
    // { key: 'business_unit_type', label: '往来单位类型', sortable: true },
    // { key: 'business_unit_id', label: '往来单位ID', sortable: true },
    { key: 'businessUnitName', label: '往来单位', sortable: true },
    //{ key: 'currency', label: '币别', sortable: true },
    //{ key: 'payable_amount', label: '应付金额', sortable: true },
    { key: 'paidAmount', label: '实付金额', sortable: true },
    //{ key: 'payee_name', label: '收款单位', sortable: true },
    { key: 'payeeName', label: '对方账户名称', sortable: true },
    // { key: 'expected_pay_date', label: '期望付款日期', sortable: true },
    // { key: 'bank_business_no', label: '银行交易流水号', sortable: true },
    // { key: 'payee_type', label: '收款单位类型', sortable: true },
    // { key: 'payee_id', label: '收款单位ID', sortable: true },
    // { key: 'f_payment_method', label: '支付方式', sortable: true },
    // { key: 'e_receipt_address', label: '电子回单地址', sortable: true },
    // { key: 'payment_org_id', label: '付款组织ID', sortable: true },
    { key: 'paymentOrgName', label: '付款组织', sortable: true },
    // { key: 'settlement_org_id', label: '结算组织ID', sortable: true },
    // { key: 'settlement_org_name', label: '结算组织名称', sortable: true },
    // { key: 'purchase_org_id', label: '采购组织ID', sortable: true },
    // { key: 'purchase_org_name', label: '采购组织名称', sortable: true },
    // { key: 'purchase_dept_id', label: '采购部门ID', sortable: true },
    // { key: 'purchase_dept_name', label: '采购部门名称', sortable: true },
    // { key: 'purchase_group_id', label: '采购组织ID', sortable: true },
    // { key: 'purchase_group_name', label: '采购组织名称', sortable: true },
    // { key: 'purchaser_id', label: '采购人员ID', sortable: true },
    // { key: 'purchaser_name', label: '采购人员名称', sortable: true },
    // { key: 'fund_type', label: '款项类型', sortable: true },
    // { key: 'project', label: '项目', sortable: true },
    { key: 'status', label: '审核状态', sortable: true },
    // { key: 'state', label: '状态', sortable: true },
    { key: 'remark', label: '备注', sortable: true },
    { key: 'attachments', label: '附件', sortable: true },
    // { key: 'create_time', label: '添加时间', sortable: true },
    // { key: 'creator', label: '添加人ID', sortable: true },
    // { key: 'update_time', label: '更新时间', sortable: true },
    // { key: 'updator', label: '更新人ID', sortable: true },
        { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10,25, 50, 100]
  const listTotals = ref(0)
  const orderBy = ref('createTime')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const condition = store.getters['financepayment/getCondition']
    const params = {
      start: start.value,
      limit: limit.value,
      order_by: orderBy.value,
      order_desc: isSortDirDesc.value === true ? 'desc':'',
    }
    Object.assign(params,condition)
    store
      .dispatch('financepayment/search', params)
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

  }
}
