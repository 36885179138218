<template>
  <b-card style="width:100%">
    <b-form
        @submit.prevent="refreshTable"
        @reset.prevent="resetCondition"
    >
      <b-row>
        <b-col v-for="(value,name,index) in page" :key="index" md="3">
          <xy-form :prop-data="value" v-model="condition[name]"></xy-form>
        </b-col>

        <b-col
            cols="12"
            md="3"
        >
          <b-button
              variant="outline-primary"
              type="submit"
              class="mr-1"
          >
            <span class="align-right">查询</span>
          </b-button>

          <b-button
                  variant="outline-primary"
                  type="reset"
                  class="mr-1"
          >
            <span class="align-right">重置</span>
          </b-button>

          <b-button
              variant="outline-primary"
              @click="importData"
              class="mr-1"
          >
            <span class="align-right">导入VMI付款单</span>
          </b-button>
          <b-button
              variant="outline-primary"
              @click="exportData()"
          >
            <span class="align-right">导出</span>
          </b-button>

        </b-col>
      </b-row>
    </b-form>
    <!--import-->
    <b-modal
            id="fileModal"
            size="lg"
            title="导入模板"
            ref="fileModal"
            hide-footer
    >

        <div>

          <b-col md="12">
            <b-form-group
                    label-cols="2"
                    label-cols-lg="2"
                    label="附件"
                    label-for="attachments_ids"
                    label-size="sm"
                    class="mb-1"
            >
              <attachment-upload theme="files"
                                 attachment_id="attachments_wh"
                                 :id="condition.attachmentsWh"
                                 object_type="purchase_inbound"
                                 @change="onUploaded"
              />
            </b-form-group>
          </b-col>
          <!--          toTime(business_time)-->

          <b-col
                  cols="12"
                  class="mt-50"
          >
            <b-button
                    variant="primary"
                    class="mr-1"
                    @click="submitInfo"
            >
              导入
            </b-button>
          </b-col>
        </div>
    </b-modal>
     <!--loading-->
    <b-modal
            id="loadingModal"
            centered
            hide-header
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            ref="loadingModal"
    >
      <p class="my-4">单据创建中，请勿关闭浏览器!</p>
    </b-modal>


    <b-modal
            style="width:200px;height:300px"
            id="modal-scrollable"
            scrollable
            title="错误信息"
            hide-footer
            centered
            ref="modal-scrollable"
    >
      <b-card-text
         v-for="content in scrollContent"
      >
        {{ content }}
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>
import { computed, onMounted, onUnmounted, reactive, toRefs } from '@vue/composition-api'
import { getCodeOptions } from '@core/utils/filter'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import financepaymentStore from './financepaymentStore'
import XyForm from '@/views/components/xy/XyForm'
import { second } from '@core/utils/dayjs/utils'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
export default {
  components: { XyForm ,AttachmentUpload},
  data() {
    return {
      scrollContent:"",
    }
  },
  props: {},
  setup(props, { emit }) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('financepayment')) store.registerModule('financepayment', financepaymentStore)

    onMounted(()=>{
      state.condition = store.getters['financepayment/getCondition']
    })

    const state = reactive({
      condition: {},
      page:{
        f_payment_no: {
          type: 'input',
          attrs: {
            id: 'f_payment_no',
            label: '付款单编号',
          },
        },
        f_payment_type: {
          type: 'select',
          attrs: {
            id: 'f_payment_type',
            label: '付款单类型',
            optionsType: 'f_payment_type',
          },
        },
        business_date: {
          type: 'datePicker',
          attrs: {
            id: 'business_date',
            label: '业务日期',
          },
        },
        status: {
          type: 'select',
          attrs: {
            id: 'status',
            label: '审核状态',
            optionsType: 'f_payment_status',
          },
        },
        item_order_no: {
          type: 'input',
          attrs: {
            id: 'item_order_no',
            label: '订单编号',
          },
        },
        oa_workflow_no: {
          type: 'input',
          attrs: {
            id: 'oa_workflow_no',
            label: 'OA流程编号',
          },
        },
        business_unit_name: {
          type: 'input',
          attrs: {
            id: 'business_unit_name',
            label: '往来单位',
          },
        },

      },
    })
    const methods = {
      refreshTable() {
        emit('refreshTable')
      },
      resetCondition() {
        state.condition = {}
        store.commit('financepayment/updateCondition', state.condition)
        methods.refreshTable()
      },
      importData(){
        this.$refs['fileModal'].show()
      },
      exportData(){
        emit('exportData')
      }

    }
    const onUploaded = function (id, attachment, result) {
      this.attachmentsWh = result
    }
    const submitInfo = function () {
      this.$refs['fileModal'].hide()
      this.$refs['loadingModal'].show()
      store.dispatch('financepayment/export', {
        'attachmentsWh': this.attachmentsWh
      }).then(res => {
        this.$refs['loadingModal'].hide()
      if (res.data.code === 0) {
         toast.success('导入成功')
           window.location.reload()
           }else{
        this.scrollContent=res.data.data
        this.$refs['modal-scrollable'].show()

          //toast.error(res.data.data)
      }
              })

    }
    return {
      ...toRefs(state),
      ...methods,
      //filter
      getCodeOptions,
      onUploaded,
      submitInfo
    }
  },
}
</script>

<style scoped>

</style>
